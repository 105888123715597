<template>

	<div class="group">

		<container :class="{ screenshot : screenshot }" ref="user_chart" :collapse_on_mount="false" :header_toggle="false">

			<template slot="container-head"><div>
				
				<div class="group-title">Graph</div>
				
				<div class="div-inputs" v-if="!screenshot">

					<select @change="select_change" v-model="datas_type">
						<option value="picks">Picks</option>
						<option value="rankings">Rankings</option>
					</select>

					<vuejs-datepicker name="date_start" @selected="date_start = $event" :placeholder="'Date début'"></vuejs-datepicker>
					<vuejs-datepicker name="date_end" :value="date_end.valueOf()" @selected="date_end = $event"></vuejs-datepicker>

				</div>

				<!-- <div class="btn-screenshot">
					<button>
						<font-awesome-icon :icon="['fas', 'download']" />
					</button>
				</div> -->

			</div></template>

			<template slot="container-body">
				<line-chart :chart_datas="get_chart_datas" :options="chart_options" />
			</template>

		</container>

	</div>

</template>

<script>

import moment from 'moment'
moment.locale( 'fr' )

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { faDownload } from '@fortawesome/free-solid-svg-icons'
// library.add( faDownload )

export default {

	name	: 'user-chart', 
	
	components	: {

		// 'font-awesome-icon' : FontAwesomeIcon,

		'vuejs-datepicker'	: () => import( 'vuejs-datepicker' ),

		'container'	: () => import( '@/components/container.vue' ),
		'line-chart'	: () => import( '@/components/charts/line.vue' ),

	},

	props	: [ 'user' ],
	mixins	: [],

	data	: function() { return {

		screenshot	: false,
		// datas_type	: 'deck_rankings',
		datas_type	: null,

		date_start	: false,
		date_end	: moment().subtract( 1, 'days' ),
		// date_end	: false,

		chart_options	: {

			scales	: {

				// xAxes	: [{
					// type	: 'time', 
				// }],

				yAxes	: [{
					display: true, 
					ticks: {
						beginAtZero	: false,	// minimum value will be 0.
						// reverse: true,
						// steps	: 1,
						stepValue	: 1 ,
						suggestedMin: 1,	// minimum will be 0, unless there is a lower value.
					},
				}]

			},

			responsive	: true,
			aspectRatio	: 2,
			maintainAspectRatio	: false,

		}, 

		chart_datas	: {

			labels: [],

			datasets	: [

				// {
				// 	label: 'Data One',
				// 	backgroundColor: 'blue',
				// 	data: [2, 13]
				// }, {
				// 	label: 'Data Two',
				// 	backgroundColor: 'red',
				// 	data: [4, 10]
				// }

			]

		},

	}}, 

	methods	: {
		
		/** ---------------------------------------*\
		*---------------------------------------- **/

		select_change	: function( event ) {	
			this.datas_type	= event.target.value
		},

		/** ---------------------------------------*\
		*---------------------------------------- **/

		update_chart	: function() {

			var chart_datas	= {}

			switch ( this.datas_type ) {

				case 'picks' : 
					chart_datas	= this.get_picks( this.date_end, this.date_start )
					this.chart_options.scales.yAxes[0].ticks.reverse	= false
				break;

				case 'rankings' : 
					chart_datas	= this.get_rankings( this.date_end, this.date_start )
					this.chart_options.scales.yAxes[0].ticks.reverse	= true
				break;

			}

			this.chart_datas.datasets	= []

			this.chart_datas.labels	= chart_datas.labels
			this.chart_datas.datasets.push( chart_datas.datasets )

		},

		/** ---------------------------------------*\
		*---------------------------------------- **/
			
		get_picks	: function() {

			var dataset	= {
				label	: 'Picks',
				backgroundColor	: 'rgba( 188, 0, 0, 0.75 )', 
				data	: [], 
				fill	: 'start', 
			}
			var labels	= []

			var past_picks	= this.user.get_past_picks( this.date_end, this.date_start )
			var pick_n	= 1

			past_picks.forEach(function( pick ) {

				labels.push( 'Pick #' + pick_n )
				dataset.data.push( parseInt( pick.score ) )

				pick_n++;

			})

			return {
				labels	: labels,
				datasets	: dataset,
			}

		},

		/** ---------------------------------------*\
		*---------------------------------------- **/
			
		get_rankings	: function() {

			var dataset	= {
				label	: 'Rankings',
				backgroundColor	: 'rgba( 188, 0, 0, 0.75 )', 
				data	: [], 
				fill	: 'start', 
			}
			var labels	= []

			var past_rankings	= this.user.get_past_rankings( this.date_end, this.date_start )
			var pick_n	= 1

			// console.log( this.user.rankings )

			past_rankings.forEach(function( ranking ) {

				labels.push( 'Pick #' + pick_n )
				dataset.data.push( parseInt( ranking.value ) )

				pick_n++;

			})

			return {
				labels	: labels,
				datasets	: dataset,
			}

		},

	},

	watch	: {

		datas_type() {
			this.update_chart()
		},

		date_start() {
			this.update_chart()
		},

		date_end() {
			this.update_chart()
		},

	},

	computed	: {

		get_chart_datas	: function() {
			return this.chart_datas
		},

	}, 

	mounted () {

		this.datas_type	= 'picks'

	}, 

}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/_base';

/** ---------------------------------------*\
*---------------------------------------- **/

.group .container::v-deep .container-body {

	height: 600px;

	& > div {
		height: 100%;
	}
	
}

/** ---------------------------------------*\
*---------------------------------------- **/

.group .container.screenshot::v-deep {

	.container-head {
		display: none;
	}

	.container-body {
		border-top: 0;
	}

	& > div {
		height: 100%;
	}
	
}



</style>